<template lang="pug">
	article#post
		h1 {{ conteudo.titulo }}
		h2 {{ conteudo.sub_titulo }}
		section#conteudo
			picture.thumb
				img(:src="conteudo.thumb", :alt="conteudo.titulo")
			div.conteudo(v-html="conteudo.texto")
			figure.destaque(v-if="conteudo.destaque_img")
				img(:src="conteudo.destaque_img")
				figcaption
					p {{ conteudo.texto_destaque }}
			.galeria(v-if="conteudo.medias && conteudo.medias.length")
				ul.galeria-list
					li.galeria-item(v-for="media, index in conteudo.medias", :class="{ ativo: galeria.ativo == index }")
						picture
							img(:src="media.url_imagem", :alt="media.legenda")
				ul.galeria-nav
					li.galeria-item(v-for="media, index in conteudo.medias", @click="toggleGaleria(index)", :class="{ ativo: galeria.ativo == index }")
						picture
							img(:src="media.url_imagem", :alt="media.legenda")
		LerMais(background="#171717" :action="isImprensa ? sectionLerMais.blog : sectionLerMais.imprensa", titulo="Você pode querer ler")
</template>

<script>
import LerMais from '@/components/LerMais/LerMais.vue'

export default {
	name: 'view-post',
	components: {
		LerMais,
	},
	data() {
		return {
			conteudo: {},
			galeria: {
				ativo: 0
			},
			sectionLerMais: {
				imprensa: {
					titulo: 'Imprensa',
					subtitulo: 'A GT Company é destaque em grandes publicações. Confira as últimas notícias.',
					botao: 'Clique aqui',
					rota: 'imprensa'
				},
				blog: {
					titulo: 'Blog',
					subtitulo: 'Fique por dentro dos negócios e novidades da GT Company.',
					botao: 'Leia mais',
					rota: 'blog'
				},
			},
		}
	},
	computed: {
		isImprensa() {
			if (!this.conteudo || !this.conteudo.categoria)
				return true
			return 'imprensa' === this.conteudo.categoria[0].slug
		},
	},
	beforeRouteUpdate(to, from, next) {
		if (to.params.slug != this.$route.params.slug) {
			this.conteudo = {}
			this.galeria.ativo = 0
			this.loadConteudo(to.params.slug)
		}
		next()
	},
	created() {
		this.loadConteudo(this.$route.params.slug)
	},
	methods: {
		loadConteudo(slug = '') {
			this.$axios
				.get(`conteudo/${slug}`)
				.then(response => this.conteudo = response.data.registros)
		},
		toggleGaleria(index = 0) {
			this.galeria.ativo = index
		}
	},
}
</script>

<style lang="stylus" scoped src="./Post.styl"></style>
